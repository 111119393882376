<template>
    <div class="appoint-view container" v-if="appoint">
        <div v-if="appoint.appointType === 'NORMAL'" class="m-normal">
            <img src="@/assets/image/normal-border.png" class="m-border">
            <div class="m-content">
                <div class="m-top"></div>
                <van-cell center title="订单编号" label="Order No." :value="appoint.appointNo" title-class="mtitle"
                          label-class="mlabel"/>
                <van-cell center title="姓名" label="Name" :value="appoint.name" title-class="mtitle"
                          label-class="mlabel"/>
                <van-cell center title="证件号" label="Id No." :value="appoint.cardNo" title-class="mtitle"
                          label-class="mlabel"/>
                <div style="height: 8px; background: #F6F6F6; margin: 0 -12px;"></div>
                <van-cell center title="石窟选择" label="Grottoe" :value="appoint.scenic.name" title-class="mtitle"
                          label-class="mlabel"/>
                <van-cell center title="参观日期" label="Date" :value="appoint.date" title-class="mtitle"
                          label-class="mlabel"/>
                <van-cell center title="进场时间" label="Time" :value="appoint.timeLabel" title-class="mtitle"
                          label-class="mlabel"/>
                <div class="m-bottom"></div>
                <div style="text-align: center;margin-top: 40px;">
                    <div id="qrcode"></div>
                    <span style="color: #E02020;">请务必截屏保存预约二维码</span>
                </div>
            </div>
            <img src="@/assets/image/normal-border.png" class="m-border">
        </div>
        <div v-if="appoint.appointType === 'VISIT'" class="m-visit">
            <img src="@/assets/image/visit-border.png" class="m-border">
            <div class="m-content">
                <div class="m-top"></div>
                <img src="@/assets/image/visit-badge.png" class="m-badge">
                <van-cell center title="订单编号" label="Order No." :value="appoint.appointNo" title-class="mtitle"
                          label-class="mlabel"/>
                <van-cell center title="姓名" label="Name" :value="appoint.name" title-class="mtitle"
                          label-class="mlabel"/>
                <van-cell center title="证件号" label="Id No." :value="appoint.cardNo" title-class="mtitle"
                          label-class="mlabel"/>
                <div style="height: 8px; background: #F6F6F6; margin: 0 -12px;"></div>
                <van-cell center title="参观日期" label="Date" :value="appoint.date" title-class="mtitle"
                          label-class="mlabel"/>
                <van-cell center title="石窟选择" label="Grottoe" :value="appoint.scenics.map(s => s.name).join('、')"
                          title-class="mtitle" label-class="mlabel"/>
                <div class="m-bottom"></div>
                <div style="text-align: center;margin-top: 40px;">
                    <div id="qrcode"></div>
                    <span style="color: #E02020;">请务必截屏保存预约二维码</span>
                </div>
            </div>
            <img src="@/assets/image/visit-border.png" class="m-border">
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import { Cell, CellGroup } from 'vant'
    import QRCode from 'qrcodejs2'
    import 'vant/lib/cell/style'
    import 'vant/lib/cell-group/style'

    Vue.use(Cell)
    Vue.use(CellGroup)
    export default {
        data () {
            return {
                appoint: null
            }
        },
        mounted () {
            let that = this
            let appointNo = that.$route.params.no
            that.$http.get('/v1/appoints/' + appointNo).then(res => {
                that.appoint = res.data
                let appoint = that.appoint
                let qrCodeText = ''
                if (appoint.appointType === 'NORMAL') {
                    qrCodeText = `普通,${appoint.appointNo},${that.encodeCardNo(appoint.cardNo)},${appoint.name},${appoint.scenic.name},${appoint.date},${appoint.appointTime}`
                } else if (appoint.appointType === 'VISIT') {
                    qrCodeText = `专业,${appoint.appointNo},${that.encodeCardNo(appoint.cardNo)},${appoint.name},${appoint.scenics.map(s => s.name).join('、')},${appoint.date}`
                }
                that.$nextTick(() => {
                    // eslint-disable-next-line no-new
                    new QRCode('qrcode', {
                        width: 132,
                        height: 132,
                        text: qrCodeText,
                        colorDark: '#000',
                        colorLight: '#ffffff',
                        correctLevel: QRCode.CorrectLevel.H
                    })
                })
            })
        }

    }
</script>
<style lang="scss">
    .appoint-view {
        padding: 0 20px;

        .m-border {
            height: 24px;
            width: 100%;
        }

        .m-content {
            margin: -16px 17px -12px;
            background-color: #FFFFFF;
            padding: 55px 12px;
            position: relative;

            .van-cell__value {
                text-align: left !important;
                padding-left: 10px;
                /*color: #000000;*/
                font-weight: normal;
            }
        }

        @media (min-width: 768px) {
            .m-content {
                margin: -29px 50px -25px;
            }
            .m-border {
                height: 50px;
            }
        }

        .m-top {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: 100%;
            height: 30px;
            opacity: 0.6;
        }

        .m-bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 10;
            width: 100%;
            height: 30px;
            opacity: 0.6;
        }

        .m-badge {
            height: 24px;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 20;
        }

        .m-visit {
            .m-top {
                background-image: linear-gradient(#77AB97FF, #AFD5C800);
                background: -webkit-linear-gradient(bottom, rgba(175,213,200,0.00) 0%, #77AB97 100%);
            }

            .m-bottom {
                background-image: linear-gradient(#AFD5C800, #77AB97FF);
                background: -webkit-linear-gradient(top, rgba(175,213,200,0.00) 0%, #77AB97 100%);
            }
        }

        .m-normal {
            .m-top {
                background-image: linear-gradient(#DEC2A2FF, #FFFFFF00);
                background: -webkit-linear-gradient(bottom, rgba(255,255,255,0.00) 0%, #C39156 100%);
            }

            .m-bottom {
                background-image: linear-gradient(#FFFFFF00, #DEC2A2FF);
                background: -webkit-linear-gradient(top, rgba(255,255,255,0.00) 0%, #C39156 100%);
            }
        }

        #qrcode {
            margin-bottom: 10px;

            img {
                margin: auto;
            }
        }
    }
</style>
